import { action, makeObservable, observable } from 'mobx'
import { update_status_vendor_lead_path } from '../../../routes.js.erb'
import Lead from '../types/Lead.type'
import LeadStatuses from '../types/LeadStatuses.type'

interface ILeadsModelProps {
  leads: Lead[] | []
  vendorId: string
}

class LeadsModel {
  clientsCount: number
  leadsList: Lead[] | []
  isPopupOpened: boolean
  isLoading: boolean
  vendorId: string
  leadStatuses: LeadStatuses

  constructor ({ leads, vendorId }: ILeadsModelProps) {
    this.clientsCount = (leads || []).length
    this.leadsList = leads
    /* this.leadsList = [
      { id: 1, name: 'Test', phone: '+38066666666', comment: 'some comment', status: 2, createdAt: '24.08.2024' },
      { id: 2, name: 'Test', phone: '+38066666666', comment: 'some comment', status: 2, createdAt: '24.08.2024' },
      { id: 3, name: 'Test', phone: '+38066666666', comment: 'some comment', status: 2, createdAt: '24.08.2024' }
    ] */
    this.isPopupOpened = false
    this.isLoading = false
    this.vendorId = vendorId
    this.leadStatuses = {
      new: 'new',
      won: 'won',
      lost: 'lost'
    }

    makeObservable(this, {
      leadsList: observable,
      isPopupOpened: observable,
      isLoading: observable,
      setIsPopupOpened: action,
      setIsLoading: action
    })
  }

  setIsPopupOpened (isOpened: boolean) {
    this.isPopupOpened = isOpened
  }

  setIsLoading (isLoading: boolean) {
    this.isLoading = isLoading
  }

  serverStatusUpdate (lead: Lead, status: string) {
    const that = this
    const leadUpdatePath = update_status_vendor_lead_path(LOCALE, this.vendorId, lead.id, { status })
    that.setIsLoading(true)

    fetch(leadUpdatePath, {
      method: 'PATCH'
    }).then(response => response.json())
      .then((parsedResponse) => {
        if (parsedResponse.message == 'Success' && parsedResponse.lead) {
          location.reload()
        }
      })
      .catch((error) => console.error('Error: ', error))
  }
}

export default LeadsModel
