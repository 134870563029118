import { makeObservable, observable, action, computed, toJS } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { login_path } from '../../../routes.js.erb'

class ToDoListModel {
  allItems
  isLoading = false
  haveInitialTemplate = true

  constructor (server_url, haveInitialTemplate) {
    this.SERVER_URL = server_url
    this.LOGIN_URL = login_path({ locale: LOCALE })

    this.allItems = {}
    this.haveInitialTemplate = haveInitialTemplate

    makeObservable(this, {
      allItems: observable,
      isLoading: observable,
      addTodo: action,
      removeTodo: action,
      reorderTodoItems: action,
      setItemDescription: action,
      setEditing: action,
      toggleDone: action,
      matureTodo: action,
      setAllTodos: action,
      setLoading: action,
      sortedItems: computed,
      copyInitialTodos: action
    })
  }

  timestamp (currentDate) {
    return Math.floor(currentDate / 1000)
  }

  touchItem (id) {
    const that = this
    this.allItems[id].client_updated_at = this.timestamp(Date.now())
    fetch(this.SERVER_URL + '/' + id, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'to-do': toJS(this.allItems[id]) })
    }).then(response => {
      if (response.status == 401) {
        this.redirectToLoginPage()
        throw new Error('Login required')
      } else {
        return response.json()
      }
    }).then(parsedResponse => {
      that.matureTodo(parsedResponse.client_uuid)
    })
  }

  matureTodo (id) {
    this.allItems[id].justCreated = false
  }

  setAllTodos (newTodos) {
    this.allItems = newTodos
  }

  addTodo (newTodo) {
    const that = this
    if (!(newTodo.sort || (newTodo.sort === 0))) {
      newTodo.sort = Object.keys(this.allItems).length
    }

    const currentID = uuidv4()
    newTodo.client_updated_at = this.timestamp(Date.now())
    newTodo.client_created_at = this.timestamp(Date.now())
    newTodo.justCreated = true
    newTodo.client_uuid = currentID
    this.allItems[currentID] = newTodo

    return fetch(this.SERVER_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'to-do': toJS(newTodo) })
    }).then(response => {
      if (response.status == 401) {
        this.redirectToLoginPage()
        throw new Error('Login required')
      } else {
        return response.json()
      }
    }).then(parsedResponse => {
      that.matureTodo(parsedResponse['to-do'].client_uuid)
    })
  }

  get sortedItems () {
    const itemsData = []
    const that = this
    let defaultIndex = 0
    for (const currentID in that.allItems) {
      const hydratedItem = { ...that.allItems[currentID], currentID }
      if (!(hydratedItem.sort || (hydratedItem.sort === 0))) {
        hydratedItem.sort = defaultIndex
      }
      itemsData.push(hydratedItem)
      defaultIndex += 1
    }
    itemsData.sort((a, b) => {
      return a.sort - b.sort
    })
    return itemsData
  }

  reorderTodoItems (result) {
    if (!result.destination) return

    const { destination, source } = result
    const direction = destination.index > source.index ? 1 : -1
    for (const currentID in this.allItems) {
      const currentItem = this.allItems[currentID]
      const currentSort = currentItem.sort
      if (currentSort === source.index) {
        currentItem.sort = destination.index
        this.touchItem(currentID)
      } else {
        if (direction > 0) {
          if ((currentSort > source.index) && (currentSort <= destination.index)) {
            currentItem.sort = currentSort - 1
            this.touchItem(currentID)
          }
        } else {
          if ((currentSort < source.index) && (currentSort >= destination.index)) {
            currentItem.sort = currentSort + 1
            this.touchItem(currentID)
          }
        }
      }
    }
  }

  setItemDescription (id, description) {
    this.allItems[id].description = description
    this.touchItem(id)
  }

  getItemDescription (id) {
    return this.allItems[id].description
  }

  setEditing (id, editing) {
    this.allItems[id].editing = editing
  }

  getEditing (id) {
    return this.allItems[id].editing
  }

  toggleDone (id) {
    this.allItems[id].done = !(this.allItems[id].done)
    this.touchItem(id)
    if (ahoy) {
      ahoy.track('Toggle done', { state: this.allItems[id].done })
    }
    if (fbq) {
      fbq('track', 'AddToWishlist', {
        content_name: 'Toggle done'
      })
    }
  }

  getDone (id) {
    return this.allItems[id].done
  }

  getJustCreated (id) {
    return this.allItems[id].justCreated
  }

  removeTodo (id) {
    fetch(this.SERVER_URL + '/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.status == 401) {
        this.redirectToLoginPage()
        throw new Error('Login required')
      } else {
        return response.json()
      }
    }).then(parsedResponse => {
      delete this.allItems[parsedResponse.client_uuid]
    })
  }

  fetchDataFromServer () {
    const that = this
    return fetch(this.SERVER_URL + '.json', {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.status == 401) {
        this.redirectToLoginPage()
        throw new Error('Login required')
      } else {
        return response.json()
      }
    })
      .then(parsedResponse => {
        that.setAllTodos(parsedResponse['to-dos'])
      })
  }

  copyInitialTodos () {
    const INITIAL_TODO_URL = this.SERVER_URL + '/copy_from_initial_todos'
    this.setLoading(true)
    fetch(INITIAL_TODO_URL)
      .then(() => this.fetchDataFromServer())
      .then(() => this.setLoading(false))
  }

  setLoading (value) {
    this.isLoading = value
  }

  redirectToLoginPage () {
    location.href = this.LOGIN_URL
  }
}

export default ToDoListModel
