import React, { FC } from 'react'
import { observer } from 'mobx-react'
import EventSimpleNewModel from '../model/EventSimpleNewModel'
import { FormattedMessage, injectIntl } from 'react-intl'

interface NameFormProps {
    model: EventSimpleNewModel,
    intl: any
}

const NameForm: FC<NameFormProps> = observer(({ model, intl }) => {
  return (
        <React.Fragment>
            <div className='simple_new title mb-5'>
                <h2>
                    <FormattedMessage id="simple_event.send_invites" defaultMessage="Send invitations"/>
                </h2>
                <h1 className='main_heading_brand'><FormattedMessage id="simple_event.free_without_limits"
                                                                     defaultMessage="as MANY as you need and for FREE"/>
                </h1>
                <h2>
                    <u>
                        <FormattedMessage id="simple_event.with_wa_sms_and_email"
                                          defaultMessage="via WhatsApp, SMS and Email"/>
                    </u>
                </h2>
            </div>
            <div className='simple_new form mb-3'>
                <label className='simple_new label mb-1' htmlFor="event_name_form">
                    <FormattedMessage id="simple_event.event_name_title"
                                      defaultMessage="Let's start with the name for your invitation."/>
                </label>
                <input
                    className={model.isInputEmpty
                      ? 'simple_new input error'
                      : 'simple_new input'
                    }
                    placeholder={intl.formatMessage({
                      id: 'simple_event.event_name_placeholder',
                      defaultMessage: 'For example: Wedding “Damir and Karygash”'
                    })}
                    type='text'
                    name="event_name_form"
                    id="event_name_form"
                    value={model.eventName}
                    onClick={() => model.setIsInputEmpty(false)}
                    onChange={(e) => {
                      model.setEventName(e.target.value)
                    }}
                />
            </div>
            <div className='simple_new actions'>
                <button className='main_submit' onClick={() => {
                  if (model.eventName.length > 0) {
                    model.setStepNumber(2)
                  } else {
                    model.setIsInputEmpty(true)
                  }
                }}>
                    <FormattedMessage id="simple_event.next" defaultMessage="Further"/>
                </button>
            </div>
        </React.Fragment>
  )
})

export default injectIntl(NameForm)
