import React from 'react'
import GuestsList from './guests/GuestsList'
import GuestsListModel from './guests/model/GuestsListModel'
import GuestsActionsToolbar from './guests/GuestsActionsToolbar'
import GuestEventInfo from './guests/GuestEventInfo'
import TranslationProvider from './providers/TranslationProvider'
import GuestsModalEnsure from './guests/GuestsModalEnsure'

export default class GuestsApp extends React.Component {
  constructor (props) {
    super(props)
    this.model = new GuestsListModel({
      initialGuests: props.initialGuests || {},
      eventId: props.current_event.slug
    })
    this.userEmail = props.userEmail

    this.locale = props.locale

    this.currentEvent = props.current_event
    this.userPhoto = props.userPhoto
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('GuestsApp mounted')
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <React.Fragment>
                    <GuestEventInfo
                        currentEvent={this.currentEvent}
                        userPhoto={this.userPhoto}
                    />
                    <div className="guests__body">
                        <GuestsList model={this.model} userEmail={this.userEmail}/>
                    </div>
                    <GuestsActionsToolbar
                        model={this.model}
                    ></GuestsActionsToolbar>
                    <GuestsModalEnsure model={this.model}/>
                </React.Fragment>
            </TranslationProvider>
    )
  }
}
