import { makeObservable, observable, action } from 'mobx'

class EventAcceptInviteModel {
  attending
  comment

  constructor ({ initialAnswer }) {
    this.comment = ''
    this.attending = 'no'
    this.setAttending(initialAnswer)

    makeObservable(this, {
      comment: observable,
      attending: observable,
      setAttending: action,
      setComment: action
    })
  }

  setAttending (newAnswer) {
    this.attending = newAnswer
  }

  signalAttending (newAnswer) {
    this.setAttending(newAnswer)

    fetch('', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        attending: this.attending,
        comment: ''
      })
    })
  }

  setComment (newComment) {
    this.comment = newComment
  }
}

export default EventAcceptInviteModel
