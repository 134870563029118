import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Map, Marker } from '@vis.gl/react-google-maps'
import MapMarker from '../../../../assets/images/vendors/icons/map_marker.svg'
import VendorServiceItem from './VendorServiceItem'
import { observer } from 'mobx-react'

interface IVendorMapProps {
    model: any
}

const VendorMap: FC<IVendorMapProps> = observer(({ model }) => {
  return (
        <div className='services_location block'>
            <h2 className='block_heading mb-4'>
                <FormattedMessage
                    id='vendors.location'
                    defaultMessage='Location'/>
                {' '}
                <FormattedMessage
                    id={`vendors.categories.${model.currentCategory.translation_key}.other`}
                    defaultMessage=''/>
                {', '}
                <FormattedMessage
                    id={`locations.short_form.${model.currentLocation.translation_key}`}
                    defaultMessage=''/>
            </h2>
            <Map
                style={{ borderRadius: '10px' }}
                defaultCenter={model.popularVendorsCoordinates[0]}
                defaultZoom={12}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                className='landing_map'
            >
                {model.popularVendorsCoordinates.map((coordinate: any, index: number) => (
                    <Marker
                        key={index}
                        position={coordinate}
                        icon={MapMarker}
                        onClick={() => model.setSelectedVendorIndex(index)}
                    />
                ))}
            </Map>

            <div className="vendor_selected">
                <VendorServiceItem locale={model.locale} location={model.currentLocation} vendor={model.popularVendors[model.selectedVendorIndex]}/>
            </div>
        </div>
  )
})

export default VendorMap
