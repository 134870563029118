import React from 'react'

import { IntlProvider } from 'react-intl'
import { translations } from '../../i18n/translations'
import { defaultLocale } from '../../i18n/default'

const TranslationProvider = ({ children, user_locale }) => {
  const locale = user_locale || defaultLocale
  const messages = translations[locale]

  return (
        <IntlProvider locale={locale} key={locale} messages={messages}>
            {children}
        </IntlProvider>
  )
}

export default TranslationProvider
