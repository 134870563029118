import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import {
  event_guest_path,
  event_send_invites_path,
  new_event_guest_path,
  event_guests_path
} from '../../../routes.js.erb'

class GuestsListModel {
  allGuests
  notShippedEmailsCount
  notShippedSmsCount
  notShippedGuestsCount
  checkedGuests
  sendInvitesPath
  addGuestPath
  guestListPath
  eventId
  answerPopUpConfig
  isGlobalChecked
  isModalEnsureOpened
  isAwaiting
  guestsUserInputs
  mockGuestData
  initialGuests
  tempNumberRegex
  periodicUpdateInterval

  constructor ({ initialGuests, eventId }) {
    const that = this
    this.eventId = eventId
    this.sendInvitesPath = event_send_invites_path(LOCALE, eventId)
    this.addGuestPath = new_event_guest_path(LOCALE, eventId)
    this.guestListPath = event_guests_path(LOCALE, eventId)
    this.tempNumberRegex = /^[0-9]*$/
    this.allGuests = {}
    this.notShippedEmailsCount = 0
    this.notShippedSmsCount = 0
    this.notShippedGuestsCount = 0
    this.checkedGuests = []
    this.answerPopUpConfig = {
      open: false,
      shownGuestId: false
    }
    this.isGlobalChecked = false
    this.isModalEnsureOpened = false
    this.isAwaiting = false
    this.initialGuests = initialGuests

    this.guestsUserInputs = initialGuests || {}
    this.mockGuestData = {
      name: '',
      email: '',
      emailErrorActive: false,
      countryCode: '+1',
      phone: '',
      inviteStatus: {
        email: 'none',
        sms: 'none'
      },
      answer: {},
      isReadyToSave: false
    }

    makeObservable(this, {
      allGuests: observable,
      checkedGuests: observable,
      answerPopUpConfig: observable,
      isGlobalChecked: observable,
      isModalEnsureOpened: observable,
      isAwaiting: observable,
      guestsUserInputs: observable,
      mockGuestData: observable,
      allGuestsIds: computed,
      totalGuestCount: computed,
      isGuestsListEmpty: computed,
      isServerSubmitDisabled: computed,
      updateInfo: action,
      addCheckedGuests: action,
      removeCheckedGuests: action,
      deleteGuest: action,
      makeGuestList: action,
      serverSendInvites: action,
      changePopupConfig: action,
      changeIsGlobalChecked: action,
      setIsAwaiting: action,
      setModalEnsureOpened: action,
      setIsReadyToSave: action,
      setGuestName: action,
      setGuestEmail: action,
      setGuestPhone: action,
      setGuestCountryCode: action,
      setMockName: action,
      setMockEmail: action,
      setMockPhone: action,
      setMockCountryCode: action,
      setMockIsReadyToSave: action,
      clearInput: action,
      clearMockInput: action
    })
    this.makeGuestList(initialGuests)
    this.periodicUpdateInterval = setInterval(() => {
      that.updateInfo()
    }, 15 * 1000)
  }

  stopPeriodicUpdates () {
    if (this.periodicUpdateInterval) {
      clearInterval(this.periodicUpdateInterval)
      this.periodicUpdateInterval = 0
    }
  }

  setIsAwaiting (newAwaiting) {
    this.isAwaiting = newAwaiting
  }

  makeGuestList (guests) {
    this.allGuests = {}
    this.notShippedEmailsCount = 0
    this.notShippedSmsCount = 0
    this.notShippedGuestsCount = 0

    for (const guestId in guests) {
      const guest = guests[guestId]
      const hasPhone = guest.phone !== '' && ((guest.inviteStatus.sms === 'none') || (guest.inviteStatus.sms === 'new'))
      const hasEmail = guest.email !== '' && ((guest.inviteStatus.email === 'none') || (guest.inviteStatus.email === 'new'))
      if (hasPhone || hasEmail) {
        this.notShippedGuestsCount += 1
      }
      if (hasPhone) {
        this.notShippedSmsCount += 1
      }
      if (hasEmail) {
        this.notShippedEmailsCount += 1
      }

      this.addGuest(guest, guestId)
    }
  }

  triggerDropdown (element: HTMLElement) {
    element.parentElement?.querySelector('.selected-flag')?.click()
  }

  get allGuestsIds () {
    return Object.keys(this.allGuests)
  }

  get isGuestsListEmpty () {
    if (Object.keys(this.allGuests).length === 0) {
      return true
    } else {
      return false
    }
  }

  updateInfo (isLoader?, successCallback?) {
    const that = this
    const guestListPathToUpdate = `${this.guestListPath}.json`
    if (isLoader) that.setIsAwaiting(true)

    fetch(guestListPathToUpdate, {
      method: 'GET'
    }).then(response => response.json())
      .then((parsedResponse) => {
        if (parsedResponse.seriliazed_guests) {
          that.makeGuestList(parsedResponse.seriliazed_guests)
        }
        if (successCallback) {
          successCallback()
        }
        that.setIsAwaiting(false)
      })
      .catch((error) => console.error('Error: ', error))
  }

  setIsReadyToSave (isReady: boolean, guestId?: number) {
    if (guestId) this.guestsUserInputs[guestId].isReadyToSave = isReady
    else {
      Object.keys(this.guestsUserInputs).forEach(input => {
        this.guestsUserInputs[input].isReadyToSave = isReady
      })
    }
  }

  setGuestName (guestId: number, name: string) {
    this.guestsUserInputs[guestId].name = name
    this.handleReadyToSaveState(guestId)
  }

  setGuestEmail (guestId: number, email: string) {
    this.guestsUserInputs[guestId].email = email
    this.handleReadyToSaveState(guestId)
  }

  setGuestPhone (guestId: number, phone: string) {
    if (this.tempNumberRegex.test(phone)) {
      this.guestsUserInputs[guestId].phone = phone
      this.handleReadyToSaveState(guestId)
    }
  }

  setGuestCountryCode (guestId: number, countryCode: string) {
    this.guestsUserInputs[guestId].countryCode = '+' + countryCode
    this.handleReadyToSaveState(guestId)
  }

  handleReadyToSaveState (guestId: number) {
    if (this.isNotSame(guestId)) {
      this.setIsReadyToSave(true, guestId)
    } else {
      this.setIsReadyToSave(false, guestId)
    }
  }

  isNotSame (guestId: number) {
    const newName = this.guestsUserInputs[guestId].name
    const newEmail = this.guestsUserInputs[guestId].email
    const newPhone = this.guestsUserInputs[guestId].phone
    const newCountryCode = this.guestsUserInputs[guestId].countryCode
    const name = this.initialGuests[guestId]?.name
    const email = this.initialGuests[guestId]?.email
    const phone = this.initialGuests[guestId]?.phone
    const countryCode = this.initialGuests[guestId]?.countryCode
    if (newName !== name) return true
    if (newEmail !== email) return true
    if (newPhone !== phone) return true
    if (newCountryCode !== countryCode) return true
    return false
  }

  get isServerSubmitDisabled () {
    if (this.mockGuestData.isReadyToSave) {
      return true
    } else {
      return false
    }
  }

  setMockName (name: string) {
    this.mockGuestData.name = name
    this.setMockIsReadyToSave()
  }

  setMockEmail (email: string) {
    this.mockGuestData.email = email
    this.setMockIsReadyToSave()
  }

  setMockCountryCode (countryCode: string) {
    this.mockGuestData.countryCode = '+' + countryCode
    this.setMockIsReadyToSave()
  }

  setMockPhone (phone: string) {
    if (this.tempNumberRegex.test(phone)) {
      this.mockGuestData.phone = phone
      this.setMockIsReadyToSave()
    }
  }

  setMockIsReadyToSave () {
    if (this.mockGuestData.name === '' &&
            this.mockGuestData.email === '' &&
            this.mockGuestData.phone === '') {
      this.mockGuestData.isReadyToSave = false
    } else {
      this.mockGuestData.isReadyToSave = true
    }
  }

  clearInput (guestId: number) {
    this.guestsUserInputs[guestId] = this.initialGuests[guestId]
    this.setIsReadyToSave(false, guestId)
  }

  clearMockInput () {
    this.mockGuestData.name = ''
    this.mockGuestData.email = ''
    this.mockGuestData.countryCode = '+1'
    this.mockGuestData.phone = ''
    this.mockGuestData.isReadyToSave = false
    this.mockGuestData.emailErrorActive = false
  }

  saveGuestOnServer (guestId: number) {
    const that = this
    fetch(event_guest_path(LOCALE, this.eventId, guestId), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userInput: toJS(that.guestsUserInputs[guestId]) })
    })
      .then(response => {
        if (response.ok) {
          that.setIsReadyToSave(false, guestId)
          that.updateInfo(true)
          return '200'
        } else {
          return response.json().then(data => {
            if (response.status === 422) {
              const guest = data.guest
              const guestId = guest.id
              if (data.errors?.email) {
                this.guestsUserInputs[guestId].emailErrorActive = true
              }
              if (data.errors?.phone) {
                this.guestsUserInputs[guestId].phoneErrorActive = true
              }
              this.addGuest(guest, guestId)
              throw new Error(data.errors.email?.[0] || data.errors.phone?.[0])
            } else {
              throw new Error('Error: Something went wrong')
            }
          })
        }
      })
      .catch(error => {
        throw new Error(error)
      })
  }

  addGuestOnServer () {
    const that = this
    fetch(event_guests_path(LOCALE, this.eventId), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userInput: toJS(that.mockGuestData) })
    })
      .then(response => {
        if (response.ok) {
          that.updateInfo(true, () => {
            that.clearMockInput()
          })
          return '200'
        } else {
          return response.json().then(data => {
            if (response.status === 422) {
              if (data.errors?.email) {
                this.mockGuestData.emailErrorActive = true
              }
              if (data.errors?.phone) {
                this.mockGuestData.phoneErrorActive = true
              }
              throw new Error(data.errors.email?.[0] || data.errors.phone?.[0])
            } else {
              throw new Error('Error: Something went wrong')
            }
          })
        }
      })
      .catch(error => {
        throw new Error(error)
      })
  }

  deleteGuest (id) {
    delete this.allGuests[id]
  }

  serverDeleteGuest (id) {
    const that = this

    fetch(event_guest_path(this.eventId, id), {
      method: 'DELETE'
    })
      .then(() => that.updateInfo(true))
      .catch((error) => console.error('Error: ', error))
  }

  serverSendInvites () {
    if (ahoy) {
      ahoy.track('GuestsListModel serverSendInvites')
    }
    if (fbq) {
      fbq('track', 'SubmitApplication', {
        content_name: 'GuestsListModel serverSendInvites'
      })
    }
    if (gtag) {
      gtag('event', 'SubmitApplication', {
        content_name: 'GuestsListModel serverSendInvites'
      })
    }
    if (ym) {
      ym(97046254, 'reachGoal', 'APLSBMT')
    }
    const that = this
    let requestBodyData = { guest_ids: that.checkedGuests }
    requestBodyData = { guest_ids: that.allGuestsIds }
    fetch(this.sendInvitesPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBodyData)
    }).then(() => {
      that.updateInfo()
    })
  }

  changePopupConfig (newConfig) {
    this.answerPopUpConfig = newConfig
  }

  getIsModalEnsureOpened () {
    return this.isModalEnsureOpened
  }

  setModalEnsureOpened (isOpened) {
    this.isModalEnsureOpened = isOpened
  }

  isPopupOpen (guestId) {
    if (this.answerPopUpConfig.open) {
      return guestId === this.answerPopUpConfig.shownGuestId
    } else {
      return false
    }
  }

  openPopupWithGuest (guestId) {
    this.changePopupConfig({
      open: true,
      shownGuestId: guestId
    })
  }

  closePopup () {
    this.changePopupConfig({
      open: false,
      shownGuestId: this.answerPopUpConfig.shownGuestId
    })
  }

  get totalGuestCount () {
    let totalCount = 0

    Object.keys(this.allGuests).forEach(guest => {
      const guestsCount = parseInt(this.allGuests[guest].count)

      if (!isNaN(guestsCount)) {
        totalCount += parseInt(this.allGuests[guest].count)
      }
    })

    return totalCount
  }

  addCheckedGuests (guestID) {
    this.checkedGuests.push(guestID)

    if (this.checkedGuests.length === Object.keys(this.allGuests).length) {
      this.isGlobalChecked = true
    }
  }

  removeCheckedGuests (guestID) {
    this.checkedGuests.splice(this.checkedGuests.indexOf(guestID), 1)
    this.isGlobalChecked = false
  }

  addGuest (newGuest, client_uuid?) {
    if (!client_uuid) {
      const uuid = uuidv4()
      this.guestsUserInputs[uuid] = newGuest
      this.allGuests[uuid] = newGuest
    } else {
      if (!this.guestsUserInputs[client_uuid] || !this.guestsUserInputs[client_uuid].isReadyToSave) {
        this.guestsUserInputs[client_uuid] = newGuest
      }
      this.allGuests[client_uuid] = newGuest
    }
  }

  navigateToAddGuest () {
    location.href = this.addGuestPath
  }

  changeIsGlobalChecked (newIsChecked) {
    this.isGlobalChecked = newIsChecked

    if (this.isGlobalChecked) {
      for (const [id] of Object.entries(this.allGuests)) {
        if (!this.checkedGuests.includes(id)) {
          this.addCheckedGuests(id)
        }
      }
    } else {
      this.checkedGuests = []
    }
  }
}

export default GuestsListModel
