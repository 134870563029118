import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { APIProvider } from '@vis.gl/react-google-maps'
import MainImg from '../../../../assets/images/vendors/services_landing_stock/main_img.jpg'
import LandingSearch from '../../../../assets/images/vendors/services_landing_stock/icons/landing_search.svg'
import LandingInfo from '../../../../assets/images/vendors/services_landing_stock/icons/landing_info.svg'
import LandingChat from '../../../../assets/images/vendors/services_landing_stock/icons/landing_chat.svg'
import PopularServicesList from './popular_services/PopularServicesList'
// import ServicesDealsList from './services_deals/SevicesDealsList'
import TranslationProvider from '../../providers/TranslationProvider'
import VendorsLandingModel from './model/VendorsLandingModel'
import VendorLandingForm from './form/VendorLandingForm'
import { LocationCategory } from './types/Vendor.type'
import { FormattedMessage } from 'react-intl'
import VendorMap from './VendorMap'

interface VendorSearchLandingProps {
    locations: LocationCategory[]
    categories: LocationCategory[]
    currentLocation: LocationCategory
    currentCategory: LocationCategory
    popularVendors: Array<any>,
    popularVendorsCoordinates: Array<any>,
    locationSlug: string,
    categorySlug: string,
    faqs: Array<any>,
    mapsAPIKey: string
    vendorsCount: string
}

export default class VendorSearchLanding extends Component<VendorSearchLandingProps> {
  model: any
  dealsList: any
  currentLocation: any
  currentCategory: any

  constructor (props: any) {
    super(props)

    this.dealsList = [
      { id: '1', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' },
      { id: '2', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' },
      { id: '3', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' },
      { id: '4', name: 'Типография Астаны', deal_text: 'Скидка 25% на печать A4 и A5' }
    ]

    this.model = new VendorsLandingModel({
      locale: props.locale,
      popularVendors: props.popularVendors,
      popularVendorsCoordinates: props.popularVendorsCoordinates,
      categories: props.categories,
      locations: props.locations,
      categorySlug: props.categorySlug,
      locationSlug: props.locationSlug,
      currentLocation: props.currentLocation,
      currentCategory: props.currentCategory,
      faqs: props.faqs,
      vendorsCount: props.vendorsCount
    })
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorSearchLanding mounted')
    }
  }

  render () {
    return (
            <APIProvider apiKey={this.props.mapsAPIKey}>
                <TranslationProvider user_locale={this.model.locale}>
                    <div className='content d-flex flex-column align-items-center'>
                        <div className="main_title block d-flex justify-content-center">
                            <div className="main_title-text">
                                <h1>
                                    <FormattedMessage
                                        id={`vendors.categories.${this.model.currentCategory.translation_key}.other`}
                                        defaultMessage=''/>
                                    <span> <FormattedMessage
                                        id={`locations.short_form.${this.model.currentLocation.translation_key}`}
                                        defaultMessage=''/></span>
                                </h1>
                                <div className="content_line"></div>
                                <p>
                                    <FormattedMessage
                                        id={'vendors.landing.hero_subheader'}
                                        defaultMessage='Quick and easy way to find vendors, only on planning.how'/>
                                </p>
                                <VendorLandingForm model={this.model}/>
                            </div>
                            <div className="main_title-img">
                                <img src={MainImg} alt="VendorServicesImg"/>
                            </div>
                        </div>
                        <div className="services_popular mt-5 block">
                            <div className="services_popular-tree">
                                <div>
                                    <FormattedMessage
                                        id={`vendors.categories.${this.model.currentCategory.translation_key}.other`}
                                        defaultMessage=''/>
                                    {' > '}
                                    <FormattedMessage
                                        id={`locations.short_form.${this.model.currentLocation.translation_key}`}
                                        defaultMessage=''/>
                                </div>
                            </div>
                            <div className="services_popular-title mt-5">
                                <h2 className='block_heading'>
                                    <FormattedMessage
                                        id='vendors.popular'
                                        defaultMessage='Popular'/>
                                    {' '}
                                    <FormattedMessage
                                        id={`vendors.categories.${this.model.currentCategory.translation_key}.other`}
                                        defaultMessage=''/>
                                    {', '}
                                    <FormattedMessage
                                        id={`locations.short_form.${this.model.currentLocation.translation_key}`}
                                        defaultMessage=''/>
                                </h2>
                                <p>
                                    <FormattedMessage
                                        id={'vendors.landing.popular_subheader'}
                                        defaultMessage='Find exact vendor, which will fit your needs the best'/>
                                </p>
                            </div>
                            <div className="services_popular-list">
                                <PopularServicesList locale={this.model.locale} length={5}
                                                     popularVendors={this.model.popularVendors}
                                                     location={this.model.currentLocation}
                                                     locationSlug={this.model.locationSlug}
                                                     categorySlug={this.model.categorySlug}
                                                     vendorsCount={this.model.vendorsCount}/>
                            </div>
                        </div>
                        {/* <div className="services_reviews block">
                        <h2 className='block_heading'>Отзывы о Типографиях в Астане</h2>
                        <div className="reviews_list d-flex gap-5 mt-5">
                            <div className="review_item d-flex flex-column align-items-center">
                                <div className="review_head d-flex gap-3">
                                    <img className='user_image' width='67px' height='67px' src={User} alt="Person"/>
                                    <div>
                                        <h4>Алия</h4>
                                        <div className="stars d-flex gap-1">
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="review_body">
                                    <p>Очень крутая типография, печатаю аж свист стоит. Очень крутая типография, печатаю
                                        аж свист стоит.</p>
                                </div>
                            </div>
                            <div className="review_item d-flex flex-column align-items-center">
                                <div className="review_head d-flex gap-3">
                                    <img className='user_image' width='67px' height='67px' src={User} alt="Person"/>
                                    <div>
                                        <h4>Алия</h4>
                                        <div className="stars d-flex">
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="review_body">
                                    <p>Очень крутая типография, печатаю аж свист стоит. Очень крутая типография, печатаю
                                        аж свист стоит.</p>
                                </div>
                            </div>
                            <div className="review_item d-flex flex-column align-items-center">
                                <div className="review_head d-flex gap-3">
                                    <img className='user_image' width='67px' height='67px' src={User} alt="Person"/>
                                    <div>
                                        <h4>Алия</h4>
                                        <div className="stars d-flex gap-1">
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                            <img src={Star} alt="Star"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="review_body">
                                    <p>Очень крутая типография, печатаю аж свист стоит. Очень крутая типография, печатаю
                                        аж свист стоит.</p>
                                </div>
                            </div>
                        </div>
                        <div className="dots d-flex gap-3 align-items-center justify-content-center">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot selected"></div>
                            <div className="dot"></div>
                        </div>
                    </div> */}

                        <VendorMap model={this.model}/>

                        {/* <div className="services_offers block">
                        <h2 className='block_heading'>Акции и предложения от типографий Астаны</h2>
                        <div className="services_offers-list">
                            <ServicesDealsList deals={this.dealsList} length={4}></ServicesDealsList>
                        </div>
                    </div> */}
                        <div className="services_faq block">
                            <FormattedMessage
                                id='vendors.landing.faq'
                                defaultMessage='Frequently Asked Questions (FAQ)'
                            >{(text: string) => <h2 className='block_heading'>{text}</h2>}</FormattedMessage>
                            <div className="services_faq-list">
                                {this.model.faqs.map((faq: any) =>
                                  (
                                        <div key={faq.question}>
                                            <div className="content_line"></div>
                                            <details className='faq_details'>
                                                <summary>{faq.question}</summary>
                                                {ReactHtmlParser(faq.answer)}
                                            </details>
                                        </div>
                                  ))}
                            </div>
                        </div>
                        <div className="services_answers block">
                            <FormattedMessage
                              id='vendors.landing.partnership_benefits'
                              values={{
                                category: <FormattedMessage id={`vendors.categories.${this.model.currentCategory.translation_key}.other`} />,
                                location: <FormattedMessage id={`locations.short_form.${this.model.currentLocation.translation_key}`} />
                              }}
                            >
                                {text => <h2 className='block_heading'>{text}</h2>}
                            </FormattedMessage>

                            <div
                                className="services_answers-list mt-5 d-flex gap-5 justify-content-center align-items-start flex-wrap">
                                <div className="services_answers-block d-flex flex-column gap-3">
                                    <img width='26px' height='26px' src={LandingSearch} alt="Search"/>
                                    <FormattedMessage tagName='h2' id='vendors.landing.quick_search' defaultMessage='Easy contact' />
                                    <FormattedMessage
                                        id='vendors.landing.quick_search_subheader'
                                        tagName='p'
                                        values={{
                                          category: <FormattedMessage id={`vendors.categories.${this.model.currentCategory.translation_key}.other`} />,
                                          location: <FormattedMessage id={`locations.short_form.${this.model.currentLocation.translation_key}`} />
                                        }}
                                    />
                                </div>
                                <div className="services_answers-block d-flex flex-column gap-3">
                                    <img width='26px' height='26px' src={LandingInfo} alt="Information"/>
                                    <h2>Нужная информация</h2>
                                    <p>Карточка каждой типографии содержит всю необходимую информацию для вас</p>
                                </div>
                                <div className="services_answers-block d-flex flex-column gap-3">
                                    <img width='26px' height='26px' src={LandingChat} alt="Respond"/>
                                    <FormattedMessage tagName='h2' id='vendors.landing.easy_contact' defaultMessage='Easy contact' />
                                    <FormattedMessage tagName='p' id='vendors.landing.easy_contact_subheader' defaultMessage='' />
                                </div>
                            </div>
                        </div>
                        {/* <div className="services_banner block">
                            <div className="services_banner_content w-100">
                                <h2>Хотите разместить типографию из Астаны на planning.how?</h2>
                                <div className="black_button">Разместить услугу</div>
                            </div>
                        </div> */}
                        <footer></footer>
                    </div>
                </TranslationProvider>
            </APIProvider>
    )
  }
}
