import { observable, action, makeObservable, toJS } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import { event_budget_categories_path } from '../../../routes.js.erb'

interface Category {
  server_id: string
  name: string
  expected: number
  actual: number
  editing: boolean
}

interface Categories {
  categories: {
    [id: string]: Category
  }
  overallExpected: number
  overallActual: number
}

interface CategoriesMap {
  'income': Categories
  'expense': Categories
}

class BudgetModel {
  eventId: any
  expectedExpenses: number
  allCategories: CategoriesMap
  errorStatus: any
  serverPath: string
  serverPostMethod: string
  serverPutMethod: string
  serverDeleteMethod: string
  pendingDeletions: Set<string>
  pendingChanges: { [id: string]: Category }

  constructor ({ initialBudgetCategories, eventId }: { initialBudgetCategories: any, eventId: any }) {
    this.eventId = eventId
    this.expectedExpenses = 0
    this.allCategories = {
      income: {
        categories: {},
        overallExpected: 0,
        overallActual: 0
      },
      expense: {
        categories: {},
        overallExpected: 0,
        overallActual: 0
      }
    }
    this.errorStatus = null
    this.serverPath = event_budget_categories_path(LOCALE, eventId)
    this.serverPostMethod = 'POST'
    this.serverPutMethod = 'PUT'
    this.serverDeleteMethod = 'DELETE'
    this.pendingDeletions = new Set()
    this.pendingChanges = {}

    makeObservable(this, {
      expectedExpenses: observable,
      allCategories: observable,
      setInitialBudgetCategoryList: action,
      updateExpectedExpenses: action,
      getCategoryExpected: action,
      getCategoryActual: action,
      getCategoryName: action,
      setEditing: action,
      setCategoryExpected: action,
      setCategoryActual: action,
      setCategoryName: action
    })

    this.setInitialBudgetCategoryList(initialBudgetCategories)
  }

  setInitialBudgetCategoryList (budgetCategories: any) {
    budgetCategories.forEach((budgetCategory: any) => {
      const category: Category = this.convertToCategory(budgetCategory)
      this.addCategory(budgetCategory.category_type, category, budgetCategory.id)
    })
  }

  convertToCategory (budgetCategory: any): Category {
    return {
      server_id: budgetCategory.id,
      name: budgetCategory.name,
      expected: parseFloat(budgetCategory.expected),
      actual: parseFloat(budgetCategory.actual),
      editing: budgetCategory.editing
    }
  }

  updateExpectedExpenses (newAmount: number) {
    this.expectedExpenses = newAmount
  }

  addCategory (type: 'income' | 'expense', category: Category, client_uuid?: string) {
    if (!client_uuid) {
      const clientId = uuidv4()
      const typeCategory = this.allCategories[type]
      typeCategory.categories[clientId] = category
      this.increaseCategoriesOverall(typeCategory, category.actual, category.expected)
      this.saveCategoryOnServer(type, category)
        .then(serverId => {
          if (typeCategory.categories[clientId]) {
            typeCategory.categories[clientId].server_id = serverId
            if (this.pendingChanges[clientId]) {
              this.changeCategoryOnServer(serverId, type, this.pendingChanges[clientId])
              delete this.pendingChanges[clientId]
            }
          } else {
            if (this.pendingDeletions.has(clientId)) {
              this.deleteCategoryOnServer(serverId)
              this.pendingDeletions.delete(serverId)
            }
          }
        })
        .catch(error => {
          this.showAnError(error)
        })
    } else {
      const typeCategory = this.allCategories[type]
      typeCategory.categories[client_uuid] = category
      this.increaseCategoriesOverall(typeCategory, category.actual, category.expected)
    }
  }

  changeCategory (type: 'income' | 'expense', category: Category, client_uuid: string) {
    const typeCategory = this.allCategories[type]
    const oldCategory = { ...typeCategory.categories[client_uuid] }
    this.setCategoryName(type, client_uuid, category.name)
    this.setCategoryExpected(type, client_uuid, category.expected)
    this.setCategoryActual(type, client_uuid, category.actual)
    const currentCategory = typeCategory.categories[client_uuid]
    this.changeCategoriesOverall(typeCategory, oldCategory, currentCategory)

    const serverId = currentCategory.server_id
    if (serverId) {
      this.changeCategoryOnServer(serverId, type, currentCategory)
    } else {
      this.pendingChanges[client_uuid] = currentCategory
    }
  }

  removeCategory (type: 'income' | 'expense', id: string) {
    const typeCategory = this.allCategories[type]
    const serverId = typeCategory.categories[id].server_id
    this.decreaseCategoriesOverall(typeCategory, typeCategory.categories[id].actual, typeCategory.categories[id].expected)
    delete typeCategory.categories[id]
    if (serverId) {
      this.deleteCategoryOnServer(serverId)
    } else {
      this.pendingDeletions.add(id)
    }
  }

  increaseCategoriesOverall (categories: Categories, actual: number, expected: number) {
    categories.overallActual += actual
    categories.overallExpected += expected
  }

  decreaseCategoriesOverall (categories: Categories, actual: number, expected: number) {
    categories.overallActual -= actual
    categories.overallExpected -= expected
  }

  changeCategoriesOverall (categories: Categories, oldCategory: Category, currentCategory: Category) {
    if (oldCategory.actual < currentCategory.actual) {
      categories.overallActual += currentCategory.actual - oldCategory.actual
    } else {
      categories.overallActual -= oldCategory.actual - currentCategory.actual
    }
    if (oldCategory.expected < currentCategory.expected) {
      categories.overallExpected += currentCategory.expected - oldCategory.expected
    } else {
      categories.overallExpected -= oldCategory.expected - currentCategory.expected
    }
  }

  getCategoryName (type: 'income' | 'expense', id: string) {
    const typeCategory = this.allCategories[type]
    return typeCategory.categories[id].name
  }

  setCategoryName (type: 'income' | 'expense', id: string, name: string) {
    const typeCategory = this.allCategories[type]
    typeCategory.categories[id].name = name
  }

  getCategoryExpected (type: 'income' | 'expense', id: string) {
    const typeCategory = this.allCategories[type]
    return typeCategory.categories[id].expected
  }

  setCategoryExpected (type: 'income' | 'expense', id: string, expected: number) {
    const typeCategory = this.allCategories[type]
    typeCategory.categories[id].expected = expected
  }

  getCategoryActual (type: 'income' | 'expense', id: string) {
    const typeCategory = this.allCategories[type]
    return typeCategory.categories[id].actual
  }

  setCategoryActual (type: 'income' | 'expense', id: string, actual: number) {
    const typeCategory = this.allCategories[type]
    typeCategory.categories[id].actual = actual
  }

  getEditing (type: 'income' | 'expense', id: string) {
    const typeCategory = this.allCategories[type]
    return typeCategory.categories[id].editing
  }

  setEditing (type: 'income' | 'expense', id : string, editing: boolean) {
    const typeCategory = this.allCategories[type]
    typeCategory.categories[id].editing = editing
  }

  saveCategoryOnServer (type: 'income' | 'expense', category: Category): Promise<any> {
    if (ahoy) {
      ahoy.track('BudgetApp click save')
    }

    const that: this = this
    const inputCategory = {
      category_type: type,
      name: category.name,
      expected: category.expected,
      actual: category.actual
    }
    that.errorStatus = null

    return fetch(this.serverPath, {
      method: this.serverPostMethod,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userInput: toJS(inputCategory) })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error: Something went wrong')
        }
      })
      .then(data => {
        return data.category.id
      })
      .catch(error => {
        that.showAnError(error)
      })
  }

  changeCategoryOnServer (id: string, type: 'income' | 'expense', category: Category) {
    if (ahoy) {
      ahoy.track('BudgetApp click change')
    }

    const that: this = this
    const inputCategory = {
      category_type: type,
      name: category.name,
      expected: category.expected,
      actual: category.actual
    }
    that.errorStatus = null
    const serverCategoryPath = `${this.serverPath}/${id}`

    fetch(serverCategoryPath, {
      method: this.serverPutMethod,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userInput: toJS(inputCategory) })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error: Something went wrong')
        }
      })
      .catch(error => {
        that.showAnError(error)
      })
  }

  deleteCategoryOnServer (id: string): void {
    if (ahoy) {
      ahoy.track('BudgetApp click delete')
    }

    const that: this = this
    that.errorStatus = null
    const serverCategoryPath = `${this.serverPath}/${id}`

    fetch(serverCategoryPath, {
      method: this.serverDeleteMethod
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error: Something went wrong')
        }
      })
      .catch(error => {
        that.showAnError(error)
      })
  }

  showAnError (error: Error): void {
    this.errorStatus = error.message
  }
}

export default BudgetModel
