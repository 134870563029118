import { action, makeObservable, observable } from 'mobx'
import { loc_cat_landing_vendors_path } from '../../../../routes.js.erb'
import { LocationCategory, Vendor } from '../types/Vendor.type'

interface IVendorsLandingModelProps {
    popularVendors: Vendor[]
    popularVendorsCoordinates: any
    locale: string
    categories: LocationCategory[]
    locations: LocationCategory[]
    categorySlug: string
    locationSlug: string
    currentLocation: LocationCategory,
    currentCategory: LocationCategory,
    faqs: any
    vendorsCount: any
}

class VendorsLandingModel {
  popularVendors: Vendor[]
  popularVendorsCoordinates: any
  locale: string
  categories: LocationCategory[]
  locations: LocationCategory[]
  faqs: any
  categorySlug: string
  locationSlug: string
  currentLocation: LocationCategory
  currentCategory: LocationCategory
  selectedCategory: string
  selectedLocation: string
  vendorsCount: any
  selectedVendorIndex: number

  constructor ({
    locale, popularVendors, popularVendorsCoordinates,
    categories, locations, categorySlug, locationSlug,
    currentLocation, currentCategory,
    faqs, vendorsCount
  }: IVendorsLandingModelProps) {
    this.locale = locale
    this.popularVendors = popularVendors
    this.popularVendorsCoordinates = popularVendorsCoordinates

    this.categories = categories
    this.locations = locations
    this.categorySlug = categorySlug
    this.locationSlug = locationSlug
    this.currentLocation = currentLocation
    this.currentCategory = currentCategory

    this.selectedCategory = this.categorySlug
    this.selectedLocation = this.locationSlug

    this.faqs = faqs || []
    this.vendorsCount = vendorsCount

    this.selectedVendorIndex = 0

    makeObservable(this, {
      selectedCategory: observable,
      selectedLocation: observable,
      selectedVendorIndex: observable,
      setSelectedCategory: action,
      setSelectedLocation: action,
      setSelectedVendorIndex: action
    })
  }

  setSelectedCategory (category: string) {
    this.selectedCategory = category
  }

  setSelectedLocation (location: string) {
    this.selectedLocation = location
  }

  setSelectedVendorIndex (index: number) {
    this.selectedVendorIndex = index
  }

  redirectToNewPage () {
    location.href = loc_cat_landing_vendors_path(LOCALE, this.selectedLocation, this.selectedCategory)
  }
}

export default VendorsLandingModel
