import React, { Component } from 'react'
import TranslationProvider from '../providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

import LoginForm from './LoginForm'
import LoginModel from './model/LoginModel'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export default class LoginApp extends Component {
  locale: string
  reCaptchaKey: string
  model: any

  constructor (props: any) {
    super(props)
    this.locale = props.locale
    this.reCaptchaKey = props.re_captcha_key
    this.model = new LoginModel({
      otp_login: props.otp_login,
      forceEmail: props.force_email,
      formType: props.form_type,
      forcePhone: props.force_phone,
      forceCountryCode: props.force_country_code
    })
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('LoginApp mounted')
    }
    if (fbq) {
      fbq('track', 'ViewContent', {
        content_name: 'LoginApp mounted'
      })
    }
    if (gtag) {
      gtag('event', 'ViewContent', {
        content_name: 'LoginApp mounted'
      })
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <React.Fragment>
                    <div className='simple_new block'>
                        <div className='simple_new content d-flex flex-column'>
                            <div className='container login d-flex m-0 p-0'>
                                <div className='d-flex sign_in_container justify-content-start'>
                                    <div
                                        className='sign_in w-100 h-100 login d-flex flex-column justify-content-start'>
                                        <h1 className='mb-4'>
                                            {this.model.currentStep == 'password'
                                              ? <FormattedMessage id='user_session.signin.user_found_title'
                                                                    defaultMessage='User found, please use form to login'/>
                                              :
                                              this.model.currentStep == 'otp' ?
                                              <FormattedMessage id='user_session.signin.enter_otp'
                                                                    defaultMessage='Please enter OTP'/>
                                                :
                                              <FormattedMessage id='user_session.signin.form_title'
                                                                    defaultMessage='Please introduce yourself'/>
                                            }
                                        </h1>
                                          <GoogleReCaptchaProvider reCaptchaKey={this.reCaptchaKey}>
                                            <LoginForm model={this.model} />
                                          </GoogleReCaptchaProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </TranslationProvider>
    )
  }
}
