import React, { FC } from 'react'
import EventSimpleNewModel from './model/EventSimpleNewModel'
import TranslationProvider from '../../providers/TranslationProvider'
import NameForm from './form/NameForm'
import WhenWhereForm from './form/WhenWhereForm'
import InfoForm from './form/InfoForm'
import { observer } from 'mobx-react'

interface EventSimpleNewContentProps {
    model: EventSimpleNewModel
}

const EventSimpleNewContent: FC<EventSimpleNewContentProps> = observer(({ model }) => {
  let currentStep
  if (model.stepNumber === 1) {
    currentStep = <NameForm model={model}/>
  }
  if (model.stepNumber === 2) {
    currentStep = <WhenWhereForm model={model}/>
  }
  if (model.stepNumber === 3) {
    currentStep = <InfoForm model={model}/>
  }

  return (
        <React.Fragment>
            <div className='simple_new block'>
                <div className='simple_new content d-flex flex-column'>
                    {currentStep}
                </div>
            </div>
        </React.Fragment>
  )
})

class EventSimpleNewApp extends React.Component {
  model: EventSimpleNewModel
  locale: string

  constructor (props: any) {
    super(props)
    this.model = new EventSimpleNewModel({
      brand_name: props.brand_name,
      starterApp: props.starterApp
    })
    this.locale = props.locale
  }

  componentDidMount (): void {
    if (ahoy) {
      ahoy.track('EventSimpleNewApp mounted')
    }
    if (fbq) {
      fbq('track', 'ViewContent', {
        content_name: 'EventSimpleNewApp mounted'
      })
    }
    if (gtag) {
      gtag('event', 'ViewContent', {
        content_name: 'EventSimpleNewApp mounted'
      })
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.locale}>
                <EventSimpleNewContent model={this.model}/>
            </TranslationProvider>
    )
  }
}

export default EventSimpleNewApp
