import { action, makeObservable, observable, toJS } from 'mobx'
import { event_guest_path, event_guests_path } from '../../../../routes.js.erb'

class GuestItemEditModel {
  eventId
  errorStatus
  emailErrorActive
  phoneErrorActive
  serverPath
  serverMethod
  guestUserInput
  emailRegex
  phoneRegex
  saveButtonDisabled

  constructor ({ eventId, currentGuestInfo }) {
    const userInputDefaults = {
      name: '',
      count: '',
      notes: '',
      email: '',
      countryCode: '+1',
      phone: ''
    }
    this.eventId = eventId
    this.saveButtonDisabled = false
    this.errorStatus = null
    this.emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    this.phoneRegex = /[^A-Za-z]/
    this.emailErrorActive = false
    this.phoneErrorActive = false
    if (currentGuestInfo.id) {
      this.guestUserInput = Object.assign({}, userInputDefaults, currentGuestInfo.user_input)
      this.serverPath = event_guest_path(LOCALE, eventId, currentGuestInfo.id)
      this.serverMethod = 'PUT'
    } else {
      this.guestUserInput = Object.assign({}, userInputDefaults)
      this.serverPath = event_guests_path(LOCALE, eventId)
      this.serverMethod = 'POST'
    }

    makeObservable(this, {
      guestUserInput: observable,
      errorStatus: observable,
      emailErrorActive: observable,
      phoneErrorActive: observable,
      saveButtonDisabled: observable,
      saveGuestOnServer: action,
      changeGuestUserName: action,
      changeGuestUserCount: action,
      changeGuestUserNotes: action,
      changeGuestUserEmail: action,
      changeGuestUserPhone: action,
      changeGuestCountryCode: action
    })
  }

  changeSaveButtonDisabled (new_status) {
    this.saveButtonDisabled = new_status
  }

  changeGuestUserName (name) {
    this.guestUserInput.name = name
  }

  changeGuestUserCount (count) {
    const sanitizedCount = count.replace(/^0+/, '')
    const newCount = sanitizedCount === '' ? this.guestUserInput.initialCount : Math.max(0, parseInt(sanitizedCount, 10)).toString()
    this.guestUserInput.count = newCount
  }

  changeGuestUserNotes (notes) {
    this.guestUserInput.notes = notes
  }

  changeGuestUserEmail (email) {
    this.guestUserInput.email = email
    if (this.emailValidationTimeout) {
      clearTimeout(this.emailValidationTimeout)
    }
    this.emailValidationTimeout = setTimeout(() => {
      if (this.guestUserInput.email.length > 0) {
        if (!this.emailRegex.test(this.guestUserInput.email)) {
          this.emailErrorActive = true
        } else {
          this.emailErrorActive = false
        }
      } else {
        this.emailErrorActive = true
      }
    }, 1000)
  }

  changeGuestUserPhone (phone) {
    this.guestUserInput.phone = phone
    if (this.guestUserInput.phone.length > 0) {
      if (!this.phoneRegex.test(this.guestUserInput.phone)) {
        this.phoneErrorActive = true
      } else {
        this.phoneErrorActive = false
      }
    } else {
      this.phoneErrorActive = true
    }
  }

  changeGuestCountryCode (countryCode) {
    this.guestUserInput.countryCode = countryCode
  }

  saveGuestOnServer () {
    const that = this
    that.errorStatus = null
    that.changeSaveButtonDisabled(true)
    fetch(this.serverPath, {
      method: this.serverMethod,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userInput: toJS(this.guestUserInput) })
    })
      .then(response => {
        if (response.ok) {
          that.navigateToGuestList()
        } else {
          that.changeSaveButtonDisabled(false)
          return response.json().then(data => {
            if (response.status === 422) {
              if (data.errors?.email) {
                this.emailErrorActive = true
              }
              if (data.errors?.phone) {
                this.phoneErrorActive = true
              }
            } else {
              throw new Error('Error: Something went wrong')
            }
          })
        }
      })
      .catch(error => {
        that.changeSaveButtonDisabled(false)
        that.showAnError(error)
      })
  }

  navigateToGuestList () {
    location.href = event_guests_path(LOCALE, this.eventId)
  }

  showAnError (error) {
    this.errorStatus = error.message
  }
}

export default GuestItemEditModel
